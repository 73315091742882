(function ($) {
    $(function () {
        var $curtain = $('#profile');
        var $curtainHeadText = $('.curtain__head[aria-controls="' + $curtain.attr('id') + '"] [data-profile-curtain-head-text]');
        var $form
        var $loader;
        var $submit;
        var $message;

        function initSubmit (event) {
            $form = $(this);

            event.preventDefault();

            $loader = $form.closest('.loader').addClass('is-active');
            $submit = $form.find('input[type="submit"], button[type="submit"]').prop('disabled', true);
            $message && $message.trigger('message-dismiss');
        }

        function fail (response) {
            if (response.responseJSON && response.responseJSON.message) {
                $message = CHWEB.message(response.responseJSON.message, 'error', false, 'top');
            }
        }

        function always () {
            $loader.removeClass('is-active');
            $submit.prop('disabled', ! $form.is(':valid'));
        }

        function login (event) {
            var $form = $(this);

            function done (response) {
                $(document).trigger('profile-login');
                $curtain
                    .one('curtain-changed', function () {
                        $message = CHWEB.message(
                            $form.data('profile-form-success').replace(
                                ':first-name',
                                response.data.attributes.first_name
                            ),
                            'message',
                            3000
                        );
                        $curtain
                            .empty()
                            .append(
                                $('<p />')
                                    .addClass('o-container   u-pad')
                                    .text("The profile is loading...")
                                )
                                .load('/user/profile');
                        $curtainHeadText.text($curtainHeadText.data('profile-curtain-head-text').isLogged);
                    })
                    .trigger('curtain-hide');
            }

            event.preventDefault();

            initSubmit.call(this, event);

            $.post($form.attr('action'), $form.serialize())
             .done(done)
             .fail(fail)
             .always(always);
        }

        function create (event) {
            var $form = $(this),
                $submit = $form.find('input[type="submit"], button[type="submit"]');

            function done (response) {
                $(document).trigger('profile-create');
                $curtain
                    .one('curtain-changed', function () {
                        $message = CHWEB.message(
                            $form.data('profile-form-success').replace(
                                ':first-name',
                                response.data.attributes.first_name
                            ),
                            'message',
                            3000
                        );
                        $curtain
                            .empty()
                            .append(
                                $('<p />')
                                    .addClass('o-container   u-pad')
                                    .text("The profile is loading...")
                                )
                                .load('/user/profile');
                        $curtainHeadText.text($curtainHeadText.data('profile-curtain-head-text').isLogged);
                    })
                    .trigger('curtain-hide');
            }

            event.preventDefault();

            initSubmit.call(this, event);

            $.post($form.attr('action'), $form.serialize())
             .done(done)
             .fail(fail)
             .always(always);
        }

        function update (event) {
            var $form = $(this),
                $password = $form.find('input[type="password"]'),
                $submit = $form.find('input[type="submit"], button[type="submit"]');

            function done (response) {
                $(document).trigger('profile-update');
                $('#profile__name-placeholder').text(response.data.attributes.first_name);
                $message = CHWEB.message(
                    $form.data('profile-form-success').replace(
                        ':first-name',
                        response.data.attributes.first_name
                    ),
                    'message',
                    3000
                );
                $password.val('');
            }

            event.preventDefault();

            initSubmit.call(this, event);

            $.put($form.attr('action'), $form.serialize())
             .done(done)
             .fail(fail)
             .always(always);
        }

        function remove (event) {
            var $form = $(this),
                $submit = $form.find('input[type="submit"], button[type="submit"]');

            function done (response) {
                $(document).trigger('profile-removed');
                $curtain
                    .one('curtain-changed', function () {
                        $message = CHWEB.message(
                            $form.data('profile-form-success').replace(
                                ':first-name',
                                response.data.attributes.first_name
                            ),
                            'message',
                            3000
                        );
                        $curtain.empty().load('/user/profile');
                        $curtainHeadText.text($curtainHeadText.data('profile-curtain-head-text').noLogged);
                    })
                    .trigger('curtain-hide');
            }

            event.preventDefault();

            initSubmit.call(this, event);

            $.delete($form.attr('action'), $form.serialize())
             .done(done)
             .fail(fail)
             .always(always);
        }

        function logout (event) {
            var $form = $(this).closest('form'),
                $submit = $form.find('input[type="submit"], button[type="submit"]');

            function done (response) {
                $(document).trigger('profile-logout');
                $curtain
                    .one('curtain-changed', function () {
                        $message = CHWEB.message(
                            $form.data('profile-form-success').replace(
                                ':first-name',
                                response.data.attributes.first_name
                            ),
                            'message',
                            3000
                        );
                        $curtain.empty().load('/user/profile');
                        $curtainHeadText.text($curtainHeadText.data('profile-curtain-head-text').noLogged);
                    })
                    .trigger('curtain-hide');
            }

            initSubmit.call(this, event);

            $.post($form.attr('action'), $form.serialize())
             .done(done)
             .fail(fail)
             .always(always);
        }

        function validateFormOnInputChange () {
            var $form = $(this).closest('form'),
                $submit = $form.find('input[type="submit"], button[type="submit"]');

            $submit.prop('disabled', ! $form[0].checkValidity());
        }

        function toggleShowHideElements (action) {
            var $this = $(this),
                geturl = $this.data('profile-' + action);

            function done () {
                $this.toggleClass('u-hidden', action === 'hide');
            }

            (geturl && geturl !== "") ? $.get(geturl).done(done) : done();
        }

        function showElements (event) {
            toggleShowHideElements.call(this, 'show');
        }

        function hideElements (event) {
            toggleShowHideElements.call(this, 'hide');
        }

        function toggleShowHideElementsWhenIsLoggedIn () {
            $('[data-profile-show]').each(showElements);
            $('[data-profile-hide]').each(hideElements);
        }

        function toggleShowHideElementsWhenNoLoggedIn () {
            $('[data-profile-show]').each(hideElements);
            $('[data-profile-hide]').each(showElements);
        }



        $(document)
            .on('keyup.profile change.profile', '[data-profile-form] :input', validateFormOnInputChange)

            .on('submit.profile', '[data-profile-form="login"]', login)
            .on('submit.profile', '[data-profile-form="create"]', create)
            .on('submit.profile', '[data-profile-form="update"]', update)
            .on('submit.profile', '[data-profile-form="remove"]', remove)
            .on('submit.profile', '[data-profile-form="logout"]', logout)

            .on('profile-login profile-create', toggleShowHideElementsWhenIsLoggedIn)
            .on('profile-logout profile-remove', toggleShowHideElementsWhenNoLoggedIn);
    });
}(jQuery));
