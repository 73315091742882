(function ($) {

    function switchOnChange() {
        var $flag = $(this);
        var $label = $('[for="' + $flag.attr('id') + '"]');
        var $flags = $('[name="' + $flag.attr('name') + '"]');

        $flags.each(function () {
            $('[for="' + $(this).attr('id') + '"]').removeClass('is-active');
        });

        $label.addClass('is-active');
    }

    $('.o-switch__flag:checked').each(function () {
        $('[for="' + $(this).attr('id') + '"]').addClass('is-active');
    });


    $(document).on('change.switch', '.o-switch__flag', switchOnChange);

}(jQuery));
