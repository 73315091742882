(function ($) {
    const $gmaps = $('[data-gmaps]');
    const options = {
        zoom: 13,
        scrollwheel: false
    };

    function drawMap(gmap, title, options) {
        const map = new google.maps.Map(gmap, options);

        function centerMap() {
            map.setCenter(options.center);
        }

        new google.maps.Marker({
            map: map,
            position: options.center,
            title: title,
            cursor: 'drag',
            bounds: new google.maps.LatLngBounds()
        });

        map.setZoom(options.zoom);

        google.maps.event.addDomListener(window, 'resize', function () {
            window.requestAnimationFrame(centerMap);
        });
    }

    function drawAddressMap(gmap, title, address, options) {
        new google.maps.Geocoder().geocode({
            'address': address
        }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                drawMap(gmap, title, $.extend({}, options, {center: results[0].geometry.location}));
            }
        });
    }

    function drawPlacesMap(gmap, places, options) {
        const bounds = new google.maps.LatLngBounds();
        const map = new google.maps.Map(gmap, options);
        const l = places.length;
        var i; 
        var center;
        var marker;
        var infowindow;
        var $activeBox;

        function centerMap() {
            map.setCenter(bounds.getCenter());
        }

        for (i = 0; i < l; i++) {
            center = new google.maps.LatLng(
                places[i].location.latitude,
                places[i].location.longitude
            );
            marker = new google.maps.Marker({
                position: center,
                map: map,
                title: places[i].title
            });
            infowindow = new google.maps.InfoWindow({
                content: (function () {
                    return [
                        places[i].title,
                        '<a href="#' + places[i].boxID + '" data-js-action-smooth-scroll>More info</a>'
                    ].join('<br />');
                }())
            });

            bounds.extend(center);

            google.maps.event.addListener(marker, 'click', (function (boxID, center, marker, infowindow) {
                return function () {
                    if ($activeBox) {
                        google.maps.event.trigger($activeBox.data('gmaps-infowindow'), 'closeclick');
                    }
                    infowindow.open(map, marker);
                    $activeBox = $('#' + boxID).addClass('is-active').focus();
                };
            }(places[i].boxID, center, marker, infowindow)));

            google.maps.event.addListener(infowindow, 'closeclick', (function (boxID) {
                return function () {
                    if ($activeBox) {
                        $activeBox.removeClass('is-active').data('gmaps-infowindow').close();
                        $activeBox = null;
                    }
                }
            }(places[i].boxID)));

            $('#' + places[i].boxID)
            .data('gmaps-infowindow', infowindow)
            .off('click.gmaps.infowindow')
            .on('click.gmaps.infowindow', (function (marker) {
                return function () {

                    const top = $(gmap).offset().top;

                    google.maps.event.trigger(marker, 'click');

                    if ($(window).scrollTop() > top) {
                        setTimeout(function () {
                            $('html,body').animate({ scrollTop: top }, "fast", "linear")
                        }, 250);
                    }
                }
            }(marker)))
            .find(':link')
            .off('click.gmaps.infowindow')
            .on('click.gmaps.infowindow', function (event) {
                event.stopImmediatePropagation();
            });
        }

        if (l > 1) {
            map.fitBounds(bounds);
        } else {
            centerMap();
        }

        google.maps.event.addDomListener(window, 'resize', function () {
            window.requestAnimationFrame(centerMap);
        });
    }

    function init () {
        const data = $(this).addClass('is-active').data('gmaps');

        switch (true) {
            case !!(data.latitude && data.longitude):
                drawMap(this, data.title, $.extend({}, options, {center: {lat: data.latitude, lng: data.longitude}}));
                break;
            case !!(data.address):
                drawAddressMap(this, data.title, data.address, options);
                break;
            case !!(data.places):
                drawPlacesMap(this, data.places, options);
                break;
        }
    }

    $gmaps.each(init);
}(jQuery));