(function ($) {
    
    var $message;
    
    function jsActionSmoothScrollOnClick(event) {
        var $this = $(this),
            $that = $($this.attr('href') || $this.data('js-action-smooth-scroll'));
        
        if ($that.length > 0) {
            if ($this.attr('href')) {
                event.preventDefault();
            }
            $('html,body').animate({ scrollTop: $that.offset().top }, "fast", "linear");
        }
    }
    
    
    $(document)
        .on('click.js-action', '[data-js-action-smooth-scroll]', jsActionSmoothScrollOnClick);
}(jQuery));
