var CHWEB = CHWEB || {};

(function ($) {

    CHWEB.message = function (message, level, timeout, position) {

        var color,
            $message,
            timeoutID;

        function dismiss () {
            $message.trigger('message-dismiss');
        }

        function messageClearTimeout () {
            timeoutID && clearTimeout(timeoutID);
        }

        switch (level) {
            case 'error':
                color = 'fall';
                break;
            case 'warning':
                color = 'sunshine';
                break;
            default:
                color = 'winter';
                break;
        }

        $message = $('<div />')
            .on('message-clear-timeout', messageClearTimeout)
            .addClass('message')
            .toggleClass('message--top', position === 'top')
            .toggleClass('message--modal', position === 'modal')
            .append(
                $('<div />')
                    .addClass('message__body message__body--c-' + color)
                    .toggleClass('message__body--top', position === 'top')
                    .toggleClass('message__body--modal', position === 'modal')
                    .attr('role', "alert")
                    .append(
                        $('<p />')
                            .addClass('o-container   h4   u-pad u-negative')
                            .text(message)
                    )
                    .append([
                        '<svg class="icon icon--s message__close   u-negative" aria-hidden="true">',
                            '<use href="/icons.svg#x-mark" />',
                        '</svg>'
                    ].join())
            )
            .appendTo(document.body)
            .delay(10)
            .queue(function (next) {
                $(this).addClass('is-active');
                next();
            });

        if (timeout > 0) {
            timeoutID = setTimeout(dismiss, timeout);
        }

        return $message;
    }
    

    function messageRemove () {
        $(this).trigger('message-clear-timeout message-dismissed').remove();
    }

    function messageDismiss () {
        $(this).trigger('message-clear-timeout').one('transitionend.message', messageRemove).removeClass('is-active');
    }

    function messageClearTimeout () {
        $(this).trigger('message-clear-timeout');
    }



    $(document)
        .on('click.message message-dismiss', '.message', messageDismiss)
        .on('mouseover.message', '.message', messageClearTimeout);
}(jQuery));
