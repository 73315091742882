(function ($) {
    const $gplaces = $('[data-gplaces]');

    function init () {
        const $this = $(this);
        const autocomplete = new google.maps.places.Autocomplete(this, {
            types: ['(regions)'],
            fields: ['address_components'],
            componentRestrictions: { country: 'us' },
        });

        function changed () {
            const place = autocomplete.getPlace();
            const components = (place.address_components ?? []).filter(
                // we are not interested in countries or postal codes
                component => component.types[0] !== 'country' && component.types[0] !== 'postal_code'
            );
            const location = components.filter(
                // we are only interested in the first and last components
                (component, index) => index === 0 || index === components.length - 1
            ).map(
                // we assume the last component is the state, of which we want the short name
                // we also get rid of ' County' as sometimes Google gets a bit confused (selecting 'Berkshire, MA'
                // would generate 'Berkshire County, MA')
                (component, index) => component[index === 0 ? 'long_name' : 'short_name'].replace(' County', '')
            );

            // we force the input value to be City, ST
            location.length === 2 && $this.val(location.join(', '));

            console.log(place.address_components, components, location);
        }

        function select (event) {
            event.key === 'Enter' ? $this.off('keydown.prevent') : $this.on('keydown.prevent', prevent);
        }

        // prevent form submissions when hitting ENTER
        function prevent (event) {
            event.key === 'Enter' && event.preventDefault();
        }

        $this.on('keydown', select).on('keydown.prevent', prevent);

        autocomplete.addListener('place_changed', changed);

        new MutationObserver(function (list, observer) {
            const containerMutationObserver = new MutationObserver(function (list) {
                const itemMutationObserver = new MutationObserver(function (list) {
                    list.forEach(function (mutation) {
                        if (mutation.target.classList.contains('pac-item-selected')) {
                            $this.val($this.val().replace(/, USA$/, ''));
                        }
                    });
                });

                list.forEach(function (mutation) {
                    mutation.addedNodes.forEach(function (node) {
                        // remove USA from the item
                        if (node.classList.contains('pac-item')) {
                            itemMutationObserver.observe(node, { attributes: true });
                            node.lastChild.textContent = node.lastChild.textContent.replace(/, USA$/, '');
                        }
                    });
                });
            });
            list.forEach(function (mutation) {
                mutation.addedNodes.forEach(function (node) {
                    // move the dropdown inside the label for the input field in order to fix its width
                    if (node.classList?.contains('pac-container')) {
                        $this.closest('.pac').append(node);
                        containerMutationObserver.observe(node, { childList: true });
                        observer.disconnect();
                    }
                });
            });
        }).observe(document.body, { childList: true });
    }

    $gplaces.each(init);
}(jQuery));
