(function ($) {
	var objBrowserGeolocation = {
		lat: '',
		lng: '',
		city: '',
		state: '',
		region: '',

		$location: null,
		$city: null,
		$state: null,

		locationDisabled: false,
		cityDisabled: false,
		stateDisabled: false,

		init: function () {
			objBrowserGeolocation.getLocation();
			objBrowserGeolocation.$location = $('input[name="location"]:not([type="hidden"])')
			objBrowserGeolocation.$city = $('input[name="info[Build City]"]:not([type="hidden"])')
			objBrowserGeolocation.$state = $('input[name="info[Build State]"]:not([type="hidden"])');
			objBrowserGeolocation.locationDisabled = objBrowserGeolocation.$location.is('[disabled]')
			objBrowserGeolocation.cityDisabled = objBrowserGeolocation.$city.is('[disabled]')
			objBrowserGeolocation.stateDisabled = objBrowserGeolocation.$state.is('[disabled]')
		},

		getLocation: function () {
			navigator.geolocation.getCurrentPosition(objBrowserGeolocation.locationSuccess, objBrowserGeolocation.locationFailure);
		},

		translateLocationFromLongLat: function () {
			objBrowserGeolocation.disabledInputs(true);
			$.get('/find-lat-lon', {
				'lat': objBrowserGeolocation.lat,
				'lon': objBrowserGeolocation.lng
			}).done(function (resultData) {
				if (resultData) {
					objBrowserGeolocation.city = resultData.city;
					objBrowserGeolocation.state = resultData.state;
					objBrowserGeolocation.region = resultData.region;
					objBrowserGeolocation.populateInput();
				} else {
					objBrowserGeolocation.locationFailure();
				}
			}).always(function () {
				objBrowserGeolocation.disabledInputs(false);
			});
		},

		populateInput: function () {
			if (objBrowserGeolocation.$location.val() === '') {
				objBrowserGeolocation.$location.val(objBrowserGeolocation.city + ', ' + objBrowserGeolocation.state);
			}
			if (objBrowserGeolocation.$city.val() === '' && objBrowserGeolocation.$state.val() === '') {
				objBrowserGeolocation.$city.val(objBrowserGeolocation.city);
				objBrowserGeolocation.$state.val(objBrowserGeolocation.region + ' (' + objBrowserGeolocation.state + ')');
			}
		},

		locationSuccess: function (position) {
			objBrowserGeolocation.lat = position.coords.latitude;
			objBrowserGeolocation.lng = position.coords.longitude;
			objBrowserGeolocation.translateLocationFromLongLat();
		},

		locationFailure: function () {
			if (objBrowserGeolocation.$location.val() === '' && objBrowserGeolocation.$location.data('ip-location')) {
				objBrowserGeolocation.$location.val(objBrowserGeolocation.$location.data('ip-location'));
			}
		},

		disabledInputs: function (disabled) {
			objBrowserGeolocation.$location.prop('disabled', disabled || objBrowserGeolocation.locationDisabled)
			objBrowserGeolocation.$city.prop('disabled', disabled || objBrowserGeolocation.cityDisabled)
			objBrowserGeolocation.$state.prop('disabled', disabled || objBrowserGeolocation.stateDisabled);
		}
	}

	$(function () {
		if (navigator.geolocation) {
			objBrowserGeolocation.init();
		}
	});
}(jQuery));
